<template>
    <div class="home-view">
        <div class="banner">
            <div class="big-logo">
                <img src="../../assets/logo-big.png" />
            </div>
            <div class="slogan">
                集合财务、业务、运维的三位一体云成本管理平台
            </div>
        </div>
        <div class="entry">
            <n-button
                type="primary"
                size="large"
                class="item login-btn"
                @click="goBusiness"
                >{{ isLogin ? '立即使用' : '登录' }}
            </n-button>
            <n-button size="large" class="item user-guide" @click="goGuide"
                >用户手册</n-button
            >
        </div>
        <div class="footer">
            Copyright © 2022~{{ new Date().getFullYear() }} MaxSaving (Cloud
            Native Team Supported)
        </div>
    </div>
</template>

<style lang="less" scoped>
.home-view {
    flex-grow: 1;
    background: #fff url('../../assets/bg.png') no-repeat;
    background-position: 0 100%;
    background-size: 100% auto;
    .banner {
        width: 100%;
        height: 50vh;
        color: @text-8-color;

        .big-logo {
            position: relative;
            text-align: center;
            padding-top: 14vh;
            img {
                width: 600px;
            }
        }

        .slogan {
            text-align: center;
            font-size: 20px;
            color: @text-8-color;
        }
    }

    .entry {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .item {
            margin: 0 15px;
            border-radius: 8px;
        }

        .user-guide {
            background-color: #fff;
        }

        .login-btn {
            width: 96px;
        }
    }

    .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        text-align: center;
        padding: 20px 0;
        color: #999;
    }
}
</style>

<script setup>
import { computed, onMounted } from 'vue';

import { useRouter, useRoute } from 'vue-router';

import { NButton, useMessage } from 'naive-ui';

import { useUserStore, useGlobalStore } from '@/stores/global';

import { redirectToMaxConnect } from '@/common/tools';

const store = useUserStore();

const globalStore = useGlobalStore();

const router = useRouter();

const route = useRoute();

const message = useMessage();

let isLogin = computed(
    () => store.currentUser.username && store.currentUser.username !== 'guest01'
);

function goBusiness() {
    if (!isLogin.value) {
        redirectToMaxConnect('/?login=1');
        return;
    }
    router.push('/cost_tree/cost/_default/_default');
}

function goGuide() {
    window.open(
        'https://confluence.mobvista.com/pages/viewpage.action?pageId=79362194'
    );
}

onMounted(() => {
    if (+route.query.login === 1) {
        message.success(
            `欢迎回来，${store.currentUser.username}，MaxSaving即将展现！`
        );
        setTimeout(() => {
            goBusiness();
        }, 1000);
    }
});
</script>
